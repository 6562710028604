import * as React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { CenteredImage } from '../../../components/centered_image';
import { H1, H2, H3 } from '../../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';
import WavefieldSeparationVideo from './resources/wavefield_separation.mp4';

function UltrasonicTransducers() {
    return (
        <>
            <H1>
                Ultrasonic Transducers
            </H1>
            <p>
                One can model P-wave versus S-wave dominant transducers within an
                elastic medium by orienting the individual point sources within the
                transducer object to be either:
            </p>
            <ul>
                <li>
                    Orthogonal to the face of the transducer (parallel with the
                    normal vector)</li>
                <li>
                    In-line with the face (perpendicular to the normal vector)
                </li>
            </ul>
            <CenteredImage>
                <StaticImage
                    src="./resources/p-vs-s_wave_dominance.png"
                    alt="P- and S-wave dominant transducer orientations"
                    width={800}
                    quality={100}
                    placeholder="tracedSVG"
                />
            </CenteredImage>
            <p>
                The disk in the above figure represents the transducer while
                the arrows pointing away from the transducer's center represent
                the orientations of the individual point sources that are
                required for achieving a P-wave or S-wave dominant transducer.
            </p>
            <H2>
                P- and S-Wave Separation
            </H2>
            <p>
                P- and S-wave separation involves isolating the respective
                compressional and shear components within the ultrasonic
                wavefield.  Below is a simple example where such a wavefield
                separation approach is used to distinctly identify the parts of
                the wavefield that correspond to the P- and S-wave arrivals.
            </p>
            <Container css={{ paddingLeft: '1ex', paddingRight: '1ex', paddingBottom: '2em' }}>
                <video
                    playsInline
                    autoPlay
                    muted
                    loop
                    css={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '75%',
                    }}
                >
                    <source src={WavefieldSeparationVideo} type="video/mp4" />
                </video>
            </Container>
            <p>
                For a comprehensive overview of separating the P- and S-wave
                components from a wavefield, see{' '}
                <a
                    href="../../docs/knowledge_base/wavefield_separation"
                    target="_self"
                >
                    here
                </a>
                .
            </p>
            <H2>
                Radiation Patterns
            </H2>
            <p>
                Transducer radiation patterns are a depiction of the maximum
                wave amplitude emitted by the transducer.  These can be used to
                get an impression of the directivity characteristics of the
                individual ultrasound elements.  Different ultrasound devices
                can often excite wave modes which are either P- or S-wave
                dominant.  That is, they can either produce waves which are
                dominated by compressional (P-) or shear (S-) waves.
            </p>
            <p>
                Being able to accurately produce digital twins of such
                ultrasound transducers is imperative for producing physically
                accurate wave simulations.
            </p>
            <p>
                The radiation patterns are plotted with two overlays:
            </p>
            <ul>
                <li>
                    <b>Black dotted-dashed hemisphere</b>: This represents the effective measurement surface over which the measurements would be taken.
                </li>
                <li>
                    <b>Red Radiation Pattern</b>: Extract the magnitudes of the radiation pattern along the blue hemisphere and then deform (scale) the black hemisphere to get the red curve.
                </li>
            </ul>
            <H3>
                P-Wave Dominant
            </H3>
            <p>
                P-wave dominant transducers excite ultrasonic waves by injecting energy normal (perpendicular) to the surface on which the transducer is placed.
            </p>
            <CenteredImage
                caption={[
                    <i>{' '}(left){' '}</i>,
                    "Compressional (P-wave) component of the transducer.",
                    <i>{' '}(right){' '}</i>,
                    "Shear (S-wave) component of the transducer.",
                    <br></br>
                ]}
            >
                <Grid doubling columns={2}>
                    <Grid.Column>
                        <StaticImage
                            src="./resources/divergence_p-wave_dominant.png"
                            alt=""
                            width={800}
                            quality={100}
                            placeholder="tracedSVG"
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <StaticImage
                            src="./resources/curl_p-wave_dominant.png"
                            alt=""
                            width={800}
                            quality={100}
                            placeholder="tracedSVG"
                        />
                    </Grid.Column>
                </Grid>
            </CenteredImage>
            <H3>
                S-Wave Dominant
            </H3>
            <p>
                S-wave dominant transducers excite ultrasonic waves by injecting energy parallel to the surface on which the transducer is placed.
            </p>
            <CenteredImage
                caption={[
                    <i>{' '}(left){' '}</i>,
                    "Compressional (P-wave) component of the transducer.",
                    <i>{' '}(right){' '}</i>,
                    "Shear (S-wave) component of the transducer.",
                    <br></br>
                ]}
            >
                <Grid doubling columns={2}>
                    <Grid.Column>
                        <StaticImage
                            src="./resources/divergence_s-wave_dominant.png"
                            alt=""
                            width={800}
                            quality={100}
                            placeholder="tracedSVG"
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <StaticImage
                            src="./resources/curl_s-wave_dominant.png"
                            alt=""
                            width={800}
                            quality={100}
                            placeholder="tracedSVG"
                        />
                    </Grid.Column>
                </Grid>
            </CenteredImage>
        </>
    );
}

export default withLayout(UltrasonicTransducers);
